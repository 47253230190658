/* custom fonts  start*/
@font-face {
  font-family: "Greycliff CF";
  src: url("../fonts/FONTSPRINGDEMO-GreycliffCFBold.eot");
  src: url("../fonts/FONTSPRINGDEMO-GreycliffCFBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFBold.woff2") format("woff2"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFBold.woff") format("woff"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFBold.ttf") format("truetype"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFBold.svg#FONTSPRINGDEMO-GreycliffCFBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-21, U+3F, U+41-5A, U+61-7A;
  /* unicode-range: U+20-21, U+3F, U+41-5A, U+61-7A, U+30-39, U+0028, U+0029,U+0025; */
}

@font-face {
  font-family: "FONTSPRING DEMO - Regular";
  src: url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.eot");
  src: url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.woff2") format("woff2"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.woff") format("woff"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.ttf") format("truetype"),
    url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.svg#FONTSPRINGDEMO-GreycliffCFRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-21, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-family: "Epilogue";
  src: url("../fonts/Epilogue/Epilogue-Light.ttf") format("truetype");

  /* url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.svg#FONTSPRINGDEMO-GreycliffCFRegular") format("svg"); */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue bold";
  src: url("../fonts/Epilogue/Epilogue-SemiBold.ttf") format("truetype");

  /* url("../fonts/FONTSPRINGDEMO-GreycliffCFRegular.svg#FONTSPRINGDEMO-GreycliffCFRegular") format("svg"); */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* color custom */
:root {
  --blue: blue;
  --orange: #fe6703;
  --red: red;
  --yellow: yellow;
  /* --green: #359200; */
  --green: #81ca00;
  --green-opacity: #81ca0040;
  --lightshade: #f5eac9;
  --white: white;
  --black: black;
  --gray-light: #f8f9fa;
  --footer-bg: #e3edde;
  --lightgreen: #35920073;
}

/* color custom */
h1,
h2,
h3,
h4,
h5,
h6,
.fw-bold {
  /* font-family: "Greycliff CF", serif !important; */
  font-family: "Epilogue bold", sans-serif !important;
  /* font-weight: bold; */
}

body {
  /* font-family: "FONTSPRING DEMO - Regular", serif !important; */
  font-family: "Epilogue", sans-serif !important;
  /* font-weight: normal; */
}

h1 {
  font-size: 41px;
  line-height: 45px;
}

h2 {
  font-size: 36px;
  line-height: 46px;
}

h3 {
  font-size: 26px;
  line-height: 34px;
}

h4 {
  font-size: 20px !important;
  line-height: 24px !important;
}

h5 {
  font-size: 18px !important;
  line-height: 28px !important;
}

h6 {
  font-size: 16px !important;
  line-height: 26px !important;
}

p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

/* custom fonts  end*/
/* navbar */
.flx-navbar-style {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
}

.flx-logo {
  position: relative;
}

.flx-navbar-logo img {
  position: absolute;
  width: 85px;
  /* right: 0; */
  top: -13px;
}

.nav-link {
  color: black !important;
}

.nav-link:hover {
  color: var(--green);
}

.hsn-border {
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
}

.hsn-badge {
  margin: 0px 0px 0px -20px;
  background-color: var(--green) !important;
  font-size: 8px !important;
}

/* dropdown style shop*/
.flx-width-shop.show {
  width: 100%;
  left: 0;
  height: 450px;
  background-color: var(--gray-light);
}

.dropdown-toggle::after {
  margin-left: 0.1em !important;
  vertical-align: -0.05em !important;
  font-family: FontAwesome;
  content: "\f107" !important;
  font-size: 14px;
  border: none !important;
  background-repeat: no-repeat;
  padding: 8px 8px !important;
  background-size: contain !important;
  height: 29px;
}

.nav-item:hover .dropdown-toggle {
  color: var(--green) !important;
}

.nav-item:hover .dropdown-toggle::after {
  color: var(--green) !important;
}

/* dropdown style shop*/

/* siderbar menu mobile */

.flx-mobile-toggle {
  float: right;
  margin: -41px 32px 0px 0px;
  position: relative;
}

.flx-dropdown-style {
  transform: translate(0px, 58px) !important;
}

/* Fixed sidenav, full height */
.sidenav {
  height: 100%;
  z-index: 1;
  padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a,
.dropdown-btn {
  padding: 12px 16px 12px 16px;
  text-decoration: none;
  color: black;
  display: block;
  border-bottom: 1px solid #3692002c !important;
  border: 0px;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

/* On mouse-over */
.sidenav a:hover,
.dropdown-btn:hover {
  color: #67ad3f;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
}

/* Optional: Style the caret down icon */
.fa-plus {
  float: right;
  padding-right: 8px;
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

/* siderbar menu mobile */
/* navbar end */
/* hero section */
.flx-two-relative {
  position: relative;
}

.flx-twoabsolute {
  position: absolute;
}

.flx-animate-relative {
  position: relative;
}

.flx-oneabsolute {
  position: absolute;
  display: none;
}

.fade-in {
  opacity: 1;
  animation: fadeInOpacity 1s ease-in;
  animation-iteration-count: 1;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.top-animate {
  /* top:  0px; */
  animation: flxanimate 1000ms ease-out;
  display: block;
  /* animation-fill-mode: forwards;
  backface-visibility: hidden; */
}

@keyframes flxanimate {
  from {
    top: -400px;
  }
  to {
    top: 0px;
  }
}
.top-animate-opposite {
  animation: flxanimate-opposite 500ms;
}

@keyframes flxanimate-opposite {
  from {
    top: 0px;
    left: 0px;
  }

  to {
    top: -500px;
    left: 20px;
  }
}

.hero-text-animate {
  top: 0px;
  animation: heroTextAnimate 1000ms;
}

@keyframes heroTextAnimate {
  from {
    top: 800px;
  }

  to {
    top: 0px;
  }
}
.bottom-animate {
  top: 376px;
  animation: bottomanimate 1000ms;
}

@keyframes bottomanimate {
  from {
    top: 800px;
  }

  to {
    top: 376px;
  }
}
.bottom-animate-opposite {
  top: 376px;
  animation: bottomanimate-opposite 1000ms;
}

@keyframes bottomanimate-opposite {
  from {
    top: 376px;
  }

  to {
    top: 1000px;
  }
}

#flx-here-section {
  /* position: relative; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  /* padding-top: 110px; */
}

#flx-here-section::before {
  content: "";
  background-image: url(../img/mask-leafe.png);
  padding: 180px;
  position: absolute;
  bottom: 48px;
  left: 0px;
  /* z-index: 50; */
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-hero-slider {
  padding: 80px 0px 110px 0px;
}

.hsn-preline p {
  width: 100%;
  max-width: 544px;
  text-align: justify;
}

.hsn-smoothies {
  color: var(--green);
}

/* button style */
.btn-solid-success {
  padding: 10px 25px !important;
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
  color: white !important;
}

.btn-solid-success:hover {
  color: var(--green) !important;
  background-color: transparent !important;
  border-color: var(--green) !important;
  padding: 10px 25px !important;
}

.btn-solid-success:active {
  padding: 10px 25px !important;
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
  color: #fff !important;
}

.btn-solid-success:focus {
  padding: 10px 25px;
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
  color: #fff !important;
  box-shadow: none !important;
}
.bg-success-opacity {
  background-color: #35920020 !important;
}
.bg-warning-opacity {
  background-color: #ffc10760 !important;
}
.bg-secondary-opacity {
  background-color: #6c757d20 !important;
}

/* second btn style*/
/* third btn */
.flx-custom-btn {
  height: 74px !important;
  width: 70px !important;
  padding: 0px 5px !important;
  background-color: transparent !important;
  color: var(--black) !important;
  border: 1px solid #000 !important;
  border-radius: 6px !important;
}

.flx-custom-btn:hover {
  background-color: transparent;
  padding: 0px 5px;
  color: var(--black);
  border: 1px solid #000;
  border-radius: 6px;
}

.flx-custom-btn:active {
  padding: 0px 5px;
  background-color: transparent !important;
  color: var(--black);
  border: 1px solid #000;
  border-radius: 6px;
}

.flx-custom-btn:focus {
  padding: 0px 5px;
  box-shadow: none !important;
  background-color: transparent !important;
  color: var(--black);
  border: 1px solid #000;
  border-radius: 6px;
}

/* third btn */

/* button style */
.btn-outline-success {
  padding: 10px 25px !important;
  background-color: transparent !important;
  color: var(--green) !important;
  border: 1px solid var(--green) !important;
  border-radius: 8px !important;
}

.p-10-25 {
  padding: 10px 25px !important;
}
.p-8-20 {
  padding: 8px 20px !important;
}
.p-2-4 {
  padding: 2px 4px !important;
}

.btn-outline-success:hover {
  color: var(--white) !important;
  background-color: var(--green) !important;
  border-color: var(--white) !important;
  padding: 10px 25px !important;
}

.btn-outline-success:active {
  padding: 10px 25px;
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
  color: #fff !important;
}

.btn-outline-success:focus {
  padding: 10px 25px;
  background-color: var(--green) !important;
  border: 1px solid var(--green) !important;
  color: #fff !important;
  box-shadow: none !important;
}

/* button style */
.flx-stock {
  font-size: 76px;
  line-height: 75px;
  color: #ffffff00;
  -webkit-text-stroke-width: 0.5px;
  opacity: 0.75;
  -webkit-text-stroke-color: var(--green);
  z-index: 0;
  position: absolute;
  top: -2px;
}

.flx-dots-slider {
  right: 48% !important;
  bottom: 120px !important;
}

.flx-slider-controller {
  display: flex;
  width: 100%;
  max-width: 690px;
  justify-content: space-between;
  position: absolute;
  bottom: 215px;
  left: 8px;
}

.flx-dots-style {
  width: 100% !important;
  max-width: 120px !important;
  height: 100% !important;
  background-color: transparent !important;
  margin: 0px 20px !important;
}

.flx-prevslider-btn {
  position: relative;
  left: 0px;
  top: 0px;
  display: inline;
  z-index: 50;
}

.flx-nextslider-btn {
  position: relative;
  top: 0px;
  right: 0px;
}

.flx-text-col {
  height: 650px;
  position: relative;
  padding: 48px 0px;
}

.flx-prevslider-btn img,
.flx-nextslider-btn img {
  width: 35px;
}

.flx-slider-top {
  padding: 50px 0px;
  margin: auto;
}

/* hero section end*/

/* our-boxes start*/

#flx-products-box {
  padding: 80px 0px;
  position: relative;
}

#flx-products-box::before {
  content: "";
  background-image: url(../img/orange-object.png);
  padding: 68px;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

/* overlay style */
.flx-overlay-container {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.hsn-box-bg:hover .flx-overlay-container {
  opacity: 1;
  width: 100%;
}

.flx-overlay-container:after {
  content: "";
  background-image: url(../object/water-object.png);
  padding: 90px;
  position: absolute;
  bottom: -42px;
  background-size: cover;
  background-repeat: no-repeat;
  right: 0;
  left: 0;
  z-index: -1;
}

.hsn-box-bg:hover .box-wish {
  padding: 0px !important;
  position: absolute;
  top: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  right: 20px;
  border-radius: 25px !important;
  display: block !important;
  width: 48px;
  height: 48px;
}

.box-wish {
  display: none !important;
}

/* .hsn-box-bg:hover::before {
  content: "";
  background-image: url(../object/heart-object.png);
  padding: 10px 11px;
  position: absolute;
  top: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  right: 20px;
} */

/* overlay style */
.hsn-box-bg {
  position: relative;
  margin: 0;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
}

.hsn-box-bg:hover {
  background-color: #fff;
}

.hsn-box-bg:hover .flx-hide-title {
  opacity: 0;
}

.hsn-box-bg:hover .flx-hide-paragh {
  opacity: 0;
}

.hsn-box-btn {
  background-color: var(--green) !important;
  padding: 8px 20px !important;
  border: none !important;
}

.hsn-box-btn:hover {
  background-color: var(--green);
  padding: 8px 20px;
}

.flx-box-btn {
  background-color: var(--green) !important;
  padding: 8px 20px !important;
  border: none !important;
}

/* our-boxes end*/

/* icon section start*/
.flx-img-strawberry {
  width: 65px;
  padding: 0px 0px 20px 0px;
}

.flx-row-mb {
  margin-bottom: -40px;
}

#flx-icon-box {
  background-color: #fe6703;
}

.hsn-preline {
  padding: 0px 30px;
}

/* icon section end*/
/* slider owl-carousel start */

#flx-owl-carousel {
  padding: 80px 0px;
}

.card {
  border: 0px;
}

.slider {
  max-width: 1100px;
  display: flex;
}

.slider .card {
  flex: 1;
  margin: 0 10px;
  background: transparent;
}

.slider .card .img {
  height: 200px;
  width: 100%;
}

.slider .card .img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slider .card .content {
  padding: 10px 20px;
}

.card .content .title {
  font-size: 25px;
  font-weight: 600;
}

.card .content .sub-title {
  font-size: 20px;
  font-weight: 600;
  color: #e74c3c;
  line-height: 20px;
}

.card .content p {
  text-align: justify;
  margin: 10px 0;
}

.card .content .btn {
  display: block;
  text-align: left;
  margin: 10px 0;
}

.card .content .btn button {
  background: #e74c3c;
  color: #fff;
  border: none;
  outline: none;
  font-size: 17px;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}

.card .content .btn button:hover {
  transform: scale(0.9);
}

/* slider owl-carousel end */
/* slider owl-carousel start */
.slider .hsn-nav-btn {
  height: 35px;
  position: absolute;
  width: 36px;
  cursor: pointer;
  top: 0;
  border-radius: 50%;
  border: 1px solid var(--green);
}

.hsn-nav-btn.prev-slide {
  background-color: var(--green) !important;
}

.slider .prev-slide {
  background: url(../svg/left-arrow-svgrepo-com.svg) no-repeat scroll 0 0;
  left: 0;
  top: 40%;
}

.slider .next-slide {
  background: url(../svg/right-arrow-svgrepo-com.svg) no-repeat scroll -24px 0px;
  right: 0;
  top: 40%;
}

.slider .prev-slide {
  background-position: center;
}

.slider .prev-slide:hover {
  background-position: center;
}

.slider .next-slide {
  background-position: center;
}

.slider .next-slide:hover {
  background-position: center;
}

/* slider owl-carousel end */

/* full width section Sustainability start */

#flx-text-section {
  background-color: #e3edde;
}

.flx-iconbox ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  padding: 0px;
}

.flx-icon-box-col {
  padding: 0px 0px 0px 80px;
}

.flx-iconbox img {
  width: 45px;
}

.btn-solid-success-color {
  color: var(--green) !important;
}

.btn-solid-success-color:hover {
  color: var(--green);
}

.btn-solid-success-color:focus {
  box-shadow: none !important;
}

/* full width section Sustainability end */

/* image and text section name vision & recipes start */
.flx-vision-leave {
  position: relative;
}

.flx-vision-leave:after {
  content: "";
  background-image: url(../object/leave.png);
  top: 0px;
  position: absolute;
  padding: 24px 26px;
  background-size: cover;
  background-repeat: no-repeat;
  right: 10px;
}

#flx-vision {
  padding: 80px 0px;
}

.flx-vision-fruite {
  position: relative;
}

.flx-vision-fruite::after {
  content: "";
  background: url(../object/vision-fruits.png);
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 108px 180px;
  z-index: 50;
  bottom: -40px;
  left: -84px;
}

.flx-text-left {
  padding: 0px 0px 0px 120px;
}

#flx-recipes {
  padding-bottom: 80px;
}

.flx-recipes-object {
  position: relative;
}

.flx-recipes-object::after {
  content: "";
  background: url(../object/SmoothieMixer.png);
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  /* padding: 141px 131px; */
  z-index: 50;
  bottom: 0;
  right: -60px;
  width: 400px;
  height: 270px;
}

/* image and text section name vision & recipes  end */

/* Don't just make a smoothie. flx-top-footer start */

#flx-top-footer {
  margin: 0px;
}

.flx-bg-footer {
  background-color: var(--green);
  position: relative;
}

.flx-topfooter-height {
  padding-bottom: 120px;
}

.flx-footer-btn {
  padding: 10px 25px !important;
  background-color: var(--green) !important;
  border: 1px solid !important;
}

.flx-footer-btn:hover {
  color: var(--green) !important;
  background-color: white !important;
  border-color: var(--green) !important;
  padding: 10px 25px !important;
}

.flx-footer-btn:active {
  padding: 10px 25px !important;
  background-color: white !important;
  border: 1px solid white !important;
  color: var(--green) !important;
}

.flx-footer-btn:focus {
  padding: 10px 25px;
  background-color: white !important;
  border: 1px solid white !important;
  color: var(--var(--green)) !important;
  box-shadow: none !important;
}

.flx-bg-footer::before {
  content: "";
  background-image: url(../img/footer_top_pic.png);
  /* padding: 247px 128px; */
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  left: -50px;
  bottom: 0px;
  z-index: 49;
  width: 450px;
  height: 450px;
}

.flx-bg-footer::after {
  content: "";
  background-image: url(../object/leave.png);
  padding: 47px 51px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  top: -100px;
  z-index: 49;
  right: 200px;
}

/* Don't just make a smoothie. flx-top-footer end */
/* footer start */
#flx-footer {
  padding: 120px 0px 50px 0px;
  color: black;
  background-color: var(--footer-bg);
  position: relative;
}

#flx-footer::after {
  content: "";
  background-image: url(../object/footer1.png);
  padding: 90px 136px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 49;
  right: 0;
  bottom: 0;
}

/* footer end */
/* ====================================about page start======================================== */
#flx-hero-section {
  height: 230px;
  position: relative;
  background-image: url(/src/assets/img/sustain-banner-bg-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 100px 0px;
}

#flx-hero-section::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-hero-section::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 0px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-subheading-color {
  color: var(--green);
}

.flx-hero-about {
  height: 450px;
  padding-top: 60px;
}

.flx-hero-about p {
  width: 69%;
  margin: auto;
}

/* hero section end*/
/* about section */
#flx-about {
  padding: 120px 0px 120px 0px;
  position: relative;
}

#flx-about:after {
  content: "";
  background-image: url(../object/Green-about.png);
  padding: 77px 68px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-about-col {
  position: relative;
}

.flx-about-stock {
  font-size: 75px;
  line-height: 80px;
  color: #ffffff00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--green);
  z-index: 0;
  position: absolute;
  top: -48px;
  left: 4px;
}

.flx-about-col::after {
  content: "";
  background-image: url(../object/leave-2.png);
  padding: 20px 31px;
  position: absolute;
  top: -65px;
  right: 316px;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-forever-object {
  position: relative;
}

/* .flx-forever-object:before {
  content: "";
  background-image: url(../object/about-forever.png);
  padding: 96px 101px;
  position: absolute;
  top: -59px;
  left: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  animation: rotateoff 0.5s linear;

} */

.flx-forever-object .disk-container {
  width: 175px;
  height: 175px;
  border-radius: 100px;
  background-color: white;
  /* box-shadow: ; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: rotate(-15deg);
  animation: rotateoff 0.5s linear;
  position: absolute;
  top: -40px;
  left: 40px;
  z-index: 11;
}
.flx-forever-object:hover .disk-container {
  transform: rotate(0deg);
  animation: rotate 0.5s linear;
}
/* .flx-forever-object:hover:before {
  content: "";
  background-image: url(../object/about-forever.png);
  padding: 96px 101px;
  position: absolute;
  top: -59px;
  left: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  transform: rotate(15deg);
  animation: rotate 0.5s linear ;
} */
@keyframes rotate {
  0% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotateoff {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@keyframes zoomout {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.95;
  }
}
@keyframes zoomin {
  0% {
    scale: 0.95;
  }
  100% {
    scale: 1;
  }
}

.flx-img-position {
  position: relative;
  z-index: 10;
  animation: zoomin 0.5s linear;
}
.flx-forever-object:hover .flx-img-position {
  position: relative;
  z-index: 10;
  scale: 0.95;
  animation: zoomout 0.5s linear;
}

/* about section */
/* full width section text How we make our smoothies start */

#flx-second-imgbox {
  background-color: var(--lightshade) !important;
  position: relative;
}

#flx-second-imgbox::before {
  content: "";
  background-image: url(../object/smoothies-object.png);
  /* padding: 225px 135px; */
  position: absolute;
  left: 20px;
  bottom: 0;
  background-size: cover;
  width: 150px;
  background-repeat: no-repeat;
  height: 250px;
}

#flx-second-imgbox::after {
  content: "";
  background-image: url(../object/leave-single.png);
  padding: 20px 20px;
  position: absolute;
  right: 0px;
  top: 450px;
  background-size: cover;
  background-repeat: no-repeat;
}

.flx-add-leaveobject {
  position: relative;
}

.flx-add-leaveobject::after {
  content: "";
  background-image: url(../object/orange-object.png);
  padding: 38px 50px;
  position: absolute;
  right: 0;
  top: -30px;
  background-size: cover;
  background-repeat: no-repeat;
}

.flx-container-width {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.flx-iconbox-about ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  padding: 0px;
}

.flx-iconbox-about img {
  width: 90px;
}

.flx-iconbox-about {
  /* margin-right: 135px; */
  border-right: 3px solid var(--green);
}

.flx-mobile {
  display: none;
}

/* full width section text How we make our smoothies start */

/* ====================================about page end======================================== */

/* ====================================Sutainability page start======================================== */
/* hero section */

#flx-sutainbility-section {
  height: 650px;
  position: relative;
  background-image: url(../img/Sutainability-banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#flx-sutainbility-section::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-sutainbility-section::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 49px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-hero-sutainbility {
  height: 450px;
  padding: 140px 0px;
}

.flx-hero-sutainbility p {
  width: 69%;
  margin: auto;
}

.flx-hero-sutainbility::after {
  content: "";
  background-image: url(../object/leave-single.png);
  padding: 20px 31px;
  position: absolute;
  bottom: 120px;
  left: 50%;
  right: 50%;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

/* hero section end*/

/* ====================================Sutainability page end======================================== */

/* ====================================contact us page start======================================== */
/* hero section */
#flx-contact-section {
  height: 650px;
  position: relative;
  background-image: url(../img/contact-banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#flx-contact-section::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-contact-section::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 49px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-hero-textsection::after {
  content: "";
  background-image: url(../object/leave-single.png);
  padding: 20px 31px;
  position: absolute;
  bottom: 220px;
  left: 50%;
  right: 50%;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

/* hero section end*/
/*---------------get in touch----------------*/
.contact-wrap {
  padding: 0px 50px 0px 0px;
}

#contant {
  background-color: transparent;
  padding: 100px 0px;
}

.form-control {
  border-radius: 0;
}

.contant-info .fa {
  margin: 10px;
  color: var(--green);
  font-weight: bold;
}

.flx-tabs-nav {
  padding-bottom: 0px;
}

.flx-tabs-style {
  background-color: #f8f9fa !important;
  border: 0px !important;
  border-bottom: 1px solid var(--green) !important;
  padding: 0px 50px 30px 0px;
  position: relative;
  z-index: 0;
}

.flx-tabs-style1 {
  background-color: #f8f9fa !important;
  border: 0px !important;
  border-bottom: 1px solid #dee2e6 !important;
  padding: 0px 50px 30px 0px;
  position: relative;
  z-index: 0;
}

#flx-text-section {
  background-color: #e3edde;
}

.flx-iconbox ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  padding: 0px;
}

.flx-icon-box-col {
  padding: 0px 0px 0px 80px;
}

.flx-iconbox img {
  width: 45px;
}

.flx-hero-textsection p {
  width: 600px;
  margin: auto;
  padding: 10px 0px;
}

/*---------------get in touch end----------------*/
/* ====================================contact us page end======================================== */

/* ====================================faqs page start======================================== */
/* hero section */

#flx-faqs-section {
  height: 650px;
  position: relative;
  background-image: url(../img/hero-faqs.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#flx-faqs-section::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-faqs-section::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 49px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-hero-faq {
  height: 450px;
  padding: 250px 0px;
}

.flx-hero-faq p {
  width: 60%;
  margin: auto;
}

.flx-hero-faq::after {
  content: "";
  background-image: url(../object/leave-single.png);
  padding: 20px 31px;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

/* hero section end*/

/* faqs styleing */
#flx-faqs-accordion {
  padding: 100px 0px;
}

.accordion-item {
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  background: white !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../icon/plus.png) !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  color: var(--green);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../icon/minus.png) !important;
  transform: rotate(-180deg) !important;
}

.accordion-button:not(.collapsed) {
  color: var(--green) !important;
  background-color: var(--white) !important;
}

button.accordion-button.collapsed {
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-item:first-of-type {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 10px;
}

.flx-faqs-border {
  border-radius: 10px !important;
}

/* ====================================faqs page end======================================== */
/* ====================================Boxui page start======================================== */

.flx-selectbox-style {
  width: 350px !important;
  height: 60px !important;
  padding: 0px 20px !important;
  border-radius: 8px !important;
  border-color: black !important;
}

.flx-selectbox-btn {
  border: 1px solid;
  background: var(--black);
  color: var(--white);
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 5px;
}

.flx-selectbox-btncustomize {
  border: 1px solid !important;
  background: transparent !important;
  color: var(--black) !important;
  padding: 7px 20px !important;
  font-size: 14px !important;
  border-radius: 5px !important;
}

.form-select:focus {
  border-color: var(--black);
  box-shadow: none;
}

.flx-social-style .fab {
  border-radius: 50%;
  border: 1px solid #000;
  padding: 6px;
  margin: 7px;
}

/* exercise to replenish */
.flx-ext-width {
  width: 100%;
  max-width: 900px;
  margin: auto;
  position: relative;
}

.flx-ext-width::before {
  content: "";
  background-image: url(../object/boxui.png);
  padding: 20px 40px;
  right: 0;
  top: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-icon-box {
  position: relative;
}

#flx-icon-box::before {
  content: "";
  background-image: url(/src/assets/object/vision-fruits.png);
  padding: 75px 130px;
  right: 160px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-icon-box::after {
  content: "";
  background-image: url(/src/assets/object/smoothies-object.png);
  padding: 103px 64px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 60px;
}

/* exercise to replenish end*/

.flx-vue-width {
  width: 100%;
  max-width: 900px;
  margin: auto;
  position: relative;
}

.flx-boxui-sliderbtn {
  width: 35px !important;
}

#flx-hero-boxui {
  background-color: #f5eaca !important;
  padding: 140px 0px 0px 0px;
}

.flx-boxuicon ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  padding: 0px;
}

.flx-boxuicon img {
  width: 25px;
}

/* ====================================Boxui page end======================================== */

/* ====================================productspopup  start======================================== */

.flx-subheader {
  padding: 0px 15px 15px 15px;
  border: none;
}

.flx-product-popup {
  padding: 170px 0px 80px 0px !important;
}

#flx-product-popup {
  padding: 40px 0px !important;
}

.modal-header {
  padding: 20px 20px !important;
  border-bottom: none !important;
}

.modal-content {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 1.3rem !important;
  outline: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

/* ====================================productspopup  end======================================== */

/* ====================================wishlist discover recipes start======================================== */
#flx-wishlist {
  padding: 70px 0px 120px 0px;
}

#flx-wishlist img {
  width: 200px;
  /* opacity: 0.5; */
}

#flx-discover-recipes {
  padding: 80px 0px 120px 0px;
  position: relative;
}

#flx-discover-recipes::before {
  content: "";
  background-image: url(../object/Green-about1.png);
  padding: 99px 80px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  right: 0;
  top: 50%;
  bottom: 50%;
}

.flx-hover-effect {
  transition: transform 0.2s;
  margin: 0 auto;
  padding: 20px;
}

.flx-hover-effect:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(0.95);
}

/* ====================================wishlist discover recipes  end======================================== */
/* ====================================Custom Smoothies page start======================================== */

#flx-customsmoothies {
  padding: 50px 0px 115px 0px;
}

.flx-customcol-bg {
  background-color: var(--lightshade);
}

.flx-from-style {
  width: 350px;
  height: 60px;
  padding: 0px 20px;
  border-radius: 8px;
}

.flx-input-icons i {
  position: absolute;
}

.icon {
  padding: 20px;
  color: var(--green);
  min-width: 50px;
  text-align: center;
}

.flx-input-icons .form-control {
  width: 100% !important;
  height: 60px !important;
  padding-left: 50px !important;
  border-radius: 8px !important;
  border: 1px solid #000 !important;
  box-sizing: none !important;
  background-color: transparent !important;
}

.flx-csmoothies-style {
  width: 100% !important;
  height: 60px !important;
  padding: 0px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #000 !important;
  background-color: transparent !important;
}

.flx-name-style {
  width: 100% !important;
  height: 60px !important;
  padding: 0px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #000 !important;
  background-color: transparent !important;
}

.flx-customsmo-bg {
  background-color: #dee2e6 !important;
  max-height: 842px !important;
  overflow: auto !important;
}

.flx-customdetailed-box {
  background-color: var(--white) !important;
  margin: 10px 0px !important;
  padding: 0px 10px 20px 10px !important;
  border-radius: 8px !important;
  position: relative !important;
}

/* -- quantity box -- */

.flx-quantity-btn {
  position: absolute;
  flex-direction: column;
  display: flex;
  width: 40px;
  height: 150px;
  top: 0;
  right: 0;
  padding: 10px 10px 0px 0px;
}

.flx-quantity-btn p {
  text-align: center;
  width: 30px;
  height: 0px;
  padding: 20px 10px;
}

/* smoothies popup detaild */
.flx-popup-detailed {
  padding: 20px 60px 20px 60px !important;
}
.max-h-842 {
  max-height: 842px !important;
}
.flx-cross-div {
  display: none;
  /* position: absolute;
    flex-direction: column;
    display: flex;
    width: 40px;
    height: 150px;
    top: 0;
    left: 0;
    padding: 10px 0px 0px 10px; */
}

.flx-cross-btn {
  border-radius: 25px;
  background-color: #d6e9cc;
  height: 30px;
}

.flx-customdetailed-box:hover .flx-cross-div {
  display: block;
  position: absolute;
  flex-direction: column;
  display: flex;
  width: 40px;
  height: 150px;
  top: 0;
  left: 0;
  padding: 10px 0px 0px 10px;
}

.flx-quantity-btn p {
  text-align: center;
  width: 30px;
  height: 0px;
  padding: 20px 10px;
}

/* smoothies popup detaild */
.flx-popup-detailed {
  padding: 20px 60px 20px 60px;
}

/* ====================================Custom Smoothies page  end======================================== */

/* ====================================Custom Smoothies page  end======================================== */
/* ====================================RightRecipes page  start======================================= */

.flx-heart-wishlist {
  z-index: 1;
  padding: 0px !important;
  position: absolute;
  top: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  right: 55px;
  height: 48px;
  width: 48px;
  border-radius: 25px !important;
}

#flx-nav-pils {
  padding: 80px 0px 120px 0px;
  position: relative;
}

#flx-nav-pils::before {
  content: "";
  background-image: url(../object/Green-about1.png);
  padding: 99px 80px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  right: 0;
  top: 50%;
  bottom: 50%;
}

.smoothie-mixer-ul {
  overflow-x: auto;
  flex-wrap: nowrap !important;
}

.smoothie-mixer-ul li {
  display: inline;
}

.flx-pils-btn .nav-item {
  color: var(--white);
  border: solid 1px #eee;
  margin: 10px;
  border-radius: 10px;
}

.flx-pils-btn .active {
  background-color: var(--green) !important;
}

.flx-pils-btn .nav-link:hover {
  color: var(--white) !important;
  background-color: var(--green) !important;
}

/* ====================================RightRecipes page  end======================================== */

/* ====================================recipesdetailed page  start======================================== */
#flx-hero-rdetailed {
  height: 100%;
  padding: 140px 0px 50px 0px;
  position: relative;
}

.flx-recipes-bg {
  background-image: url(/src/assets/img/shaperecipes.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 510px;
  width: 85%;
}

/* .flx-recipes-bg img {
  margin: -68px 0px 0px 0px;
} */

.flx-rdetailed-animation {
  transition: transform 0.2s;
  margin: 0 auto;
  padding: 20px;
}

.flx-rdetailed-animation:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(0.95);
}

#flx-iconb-rdetailed {
  background-color: #fe6703;
}

.flx-rdetailed-width {
  width: 100%;
  max-width: 900px;
  margin: auto;
  position: relative;
}

#flx-iconb-rdetailed {
  position: relative;
}

#flx-iconb-rdetailed::after {
  content: "";
  background-image: url(/src/assets/object/smoothies-object.png);
  padding: 103px 64px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 60px;
}

#flx-iconb-rdetailed::before {
  content: "";
  background-image: url(/src/assets/object/vision-fruits.png);
  padding: 75px 130px;
  right: 160px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-rdetailed-leave {
  position: relative;
}

.flx-rdetailed-leave::before {
  content: "";
  background-image: url(/src/assets/object/leave-single.png);
  padding: 20px 31px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 50%;
  top: -40px;
  left: 50%;
}

/* progressbar style*/
#flx-rdetailed-bar {
  padding: 100px 0px 0px 0px;
}

.flx-rdetailed-progresbar .progress {
  display: flex;
  height: 20px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin: 0px 0px 40px 0px;
}

.flx-rdetailed-probar .bg-success {
  background-color: var(--green) !important;
}

.flx-rdetailed-m {
  margin-bottom: 40px;
}

.flx-rdetailed-leaves {
  position: relative;
}

/* ====================================recipesdetailed page  end======================================== */

/* ====================================Ingredients page  start======================================== */
#flx-hero-ingredients {
  height: 600px;
  position: relative;
  background-image: url(/src/assets/img/Ingredients-hero.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 100px 0px;
}

#flx-hero-ingredients::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-hero-ingredients::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 49px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ====================================Ingredients page  END ======================================== */

/* ====================================Shopping Cart page  start======================================== */
#flx-shoppingcart-section {
  height: 650px;
  position: relative;
  background-image: url(../img/hero-faqs.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#flx-shoppingcart-section::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-shoppingcart-section::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 49px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-shopping {
  padding: 40px 0px 80px 0px;
}

.flx-hero-shopping {
  height: 450px;
  padding: 250px 0px;
}

.flx-hero-shopping::after {
  content: "";
  background-image: url(/src/assets/icon/shopping.png);
  padding: 37px 36px;
  position: absolute;
  bottom: 120px;
  left: 48%;
  right: 52%;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-box-shopping {
  background-color: var(--white);
}

/* ====================================Shopping Cart  page  End======================================== */
/* ====================================recipesdetailed page  start======================================== */
#flx-hero-rdetailed {
  height: 100%;
  padding: 150px 0px 50px 0px;
  position: relative;
}

/* .flx-recipes-bg img {
  margin: -68px 0px 0px 0px;
} */

.flx-rdetailed-animation {
  transition: transform 0.2s;
  margin: 0 auto;
  padding: 20px;
}

.flx-rdetailed-animation:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(0.95);
}

#flx-iconb-rdetailed {
  background-color: #fe6703;
}

.flx-rdetailed-width {
  width: 100%;
  max-width: 900px;
  margin: auto;
  position: relative;
}

#flx-iconb-rdetailed {
  position: relative;
}

#flx-iconb-rdetailed::after {
  content: "";
  background-image: url(/src/assets/object/smoothies-object.png);
  padding: 103px 64px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 60px;
}

#flx-iconb-rdetailed::before {
  content: "";
  background-image: url(/src/assets/object/vision-fruits.png);
  padding: 75px 130px;
  right: 160px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-rdetailed-leave {
  position: relative;
}

.flx-rdetailed-leave::before {
  content: "";
  background-image: url(/src/assets/object/leave-single.png);
  padding: 20px 31px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 50%;
  top: -40px;
  left: 50%;
}

#flx-hero-rdetailed::after {
  content: "";
  background-image: url(/src/assets/object/Green-about1.png);
  padding: 81px 64px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 105px;
}

/* progressbar style*/
#flx-rdetailed-bar {
  padding: 100px 0px 0px 0px;
}

.flx-rdetailed-progresbar .progress {
  display: flex;
  height: 20px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin: 0px 0px 40px 0px;
}

.flx-rdetailed-probar .bg-success {
  background-color: var(--green) !important;
}

.flx-rdetailed-m {
  margin-bottom: 40px;
}

.flx-rdetailed-leaves {
  position: relative;
}

.flx-rdetailed-leaves > span::after {
  content: "";
  background-image: url(/src/assets/object/leave-single.png);
  padding: 20px 31px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: -10%;
  bottom: 5px;
  left: 100%;
}

/* ingredients detailed */
.breadcrumb-item a {
  color: var(--green) !important;
  text-decoration: none !important;
}
.breadcrumb-item .active {
  color: var(--green) !important;
  text-decoration: none !important;
}
.breadcrumb-item .disable {
  color: #6c757d !important;
  text-decoration: none !important;
  pointer-events: none !important;
}

.breadcrumb-item a:hover {
  color: var(--green);
  text-decoration: none;
}

#flx-idetailed-probr {
  padding: 40px 0px 90px 0px;
}

#flx-other-ingredients {
  padding: 50px 0px 100px 0px;
}

/* ====================================recipesdetailed page  end======================================== */

.flx-nutrients-pils {
  background-color: transparent;
  border: none !important;
}

.flx-hsn {
  max-height: 390px;
  overflow: auto;
}

/* ====================================smoothiesmixer page  Start======================================== */
#flx-hero-smoothiemixer {
  height: 500px;
  position: relative;
  background-image: url(/src/assets/img/sustain-banner-bg-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 100px 0px;
}

#flx-hero-smoothiemixer::before {
  content: "";
  background-image: url(../object/orange-hero.png);
  padding: 66px 80px;
  position: absolute;
  bottom: 0;
  left: -46px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

#flx-hero-smoothiemixer::after {
  content: "";
  background-image: url(../object/vision-fruits.png);
  padding: 80px 135px;
  position: absolute;
  bottom: 49px;
  right: 30px;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
}

.flx-smoothiemixer-bg {
  background-image: url(/src/assets/img/shaperecipes.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 510px;
  width: 85%;
}

#flx-about-smoothies {
  padding: 100px 0px 0px 0px;
}

#flx-taste-smoothies {
  position: relative;
  padding: 0px 0px 120px 0px;
}

#flx-taste-smoothies:after {
  content: "";
  background-image: url(../object/Green-about.png);
  padding: 77px 68px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ====================================smoothiesmixer page  end======================================== */

/* ====================================login page  start======================================== */
#flx-login {
  background-image: url(../img/login-bg.png);
  padding: 170px 0px 80px 0px;
}

.flx-login-icons i {
  position: absolute;
}

.flx-icon {
  padding: 17px;
  color: var(--green);
  min-width: 50px;
  text-align: center;
}

.flx-login-icons .form-control {
  width: 100%;
  height: 50px;
  padding-left: 50px;
  border-radius: 8px !important;
  border: none;
  box-shadow: none;
  background-color: #eaf4e5;
}

.flx-login-icons .form-controls {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  border-radius: 8px !important;
  border: none;
  box-shadow: none;
  background-color: #eaf4e5;
}

/* ====================================login page  end======================================== */

/* media queries start font family*/
@media (max-width: 991px) {
  h1 {
    font-size: 25px;
    line-height: 29px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
  }

  h6 {
    font-size: 16px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .flx-recipes-bg {
    height: 410px;
  }
}

@media (max-width: 767px) {
  .carousel_text_bg {
    background-color: rgba(255, 255, 255, 0.5);
    /* position: absolute;     */
  }
  .h-md-900 {
    height: 800px;
  }
  h1 {
    font-size: 28px;
    line-height: 32px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
  }

  h6 {
    font-size: 16px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .flx-recipes-bg {
    height: 530px;
  }

  .hsn-box-bg .box-wish {
    padding: 0px !important;
    position: absolute;
    top: 50px !important;
    background-size: cover;
    background-repeat: no-repeat;
    right: 20px;
    border-radius: 25px !important;
    display: block !important;
    width: 48px;
    height: 48px;
  }

  .box-wish {
    display: none !important;
  }

  #flx-hero-rdetailed::after {
    opacity: 0.5;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 26px;
    line-height: 32px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
  }

  h6 {
    font-size: 16px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}

/* media queries end font family*/

/* XX-Large devices (larger desktops, 1400px and up) */

@media (max-width: 1400px) {
  .flx-dots-slider {
    right: 38% !important;
    bottom: 135px !important;
  }

  .flx-slider-controller {
    display: flex;
    width: 100%;
    max-width: 590px;
    justify-content: space-between;
    position: absolute;
    bottom: 230px;
    left: 8px;
  }

  .flx-bg-footer::after {
    content: "";
    background-image: url(../object/leave.png);
    padding: 42px 46px;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: -111px;
    z-index: 49;
    right: 50px;
  }

  .flx-bg-footer::before {
    content: "";
    background-image: url(../img/footer_top_pic.png);
    /* padding: 247px 128px; */
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: -100px;
    bottom: 0px;
    z-index: 49;
    width: 450px;
    height: 450px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */

/* X-Large devices (large desktops, 1200px and up) */

@media (max-width: 1199px) {
  .flx-forever-object .disk-container {
    top: -50px;
    left: 60px;
  }
  .flx-rdetailed-leaves > span::after {
    left: 45%;
    right: auto;
  }
  #flx-taste-smoothies {
    position: relative;
    padding: 0px 0px 120px 0px;
  }
  .svg-container {
    width: 450px !important;
    height: 500px !important;
    resize: both !important;
  }
  .flx-text-col {
    height: 600px;
    position: relative;
  }

  #flx-here-section::before {
    padding: 136px;
    position: absolute;
    bottom: 34px;
    left: 0px;
  }

  .flx-dots-slider {
    right: 40% !important;
    bottom: 60px !important;
  }

  .flx-prevslider-btn img,
  .flx-nextslider-btn img {
    width: 30px;
  }

  .flx-slider-controller {
    width: 60%;
    bottom: 158px;
    left: 8px;
  }

  .flx-dots-style {
    width: 100% !important;
    max-width: 120px !important;
    height: 100% !important;
    background-color: transparent !important;
    margin: 0px 6px !important;
  }

  .flx-animate-img {
    top: 251px;
  }

  /* footer */

  .flx-bg-footer::before {
    content: "";
    background-image: url(../img/footer_top_pic.png);
    /* padding: 222px 115px; */
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0px;
    bottom: -80px;
    z-index: 49;
    width: 330px;
    height: 330px;
  }

  .flx-bg-footer::after {
    content: "";
    background-image: url(../object/leave.png);
    padding: 42px 46px;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: -99px;
    z-index: 49;
    right: 40px;
  }

  /*===========================================================about page start media query ========================================*/
  /* hero style */
  .flx-hero-about {
    height: 450px;
    padding-top: 40px;
  }

  /* about section */
  .flx-about-col::after {
    content: "";
    background-image: url(../object/leave-2.png);
    padding: 20px 31px;
    position: absolute;
    top: -65px;
    right: 140px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  /* 
  .flx-forever-object:before {
    content: "";
    background-image: url(../object/about-forever.png);
    padding: 96px 101px;
    position: absolute;
    top: -82px;
    left: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  } */

  /*===========================================================about page end media query ========================================*/
  /*===========================================================sutainability page start media query ================================*/
  #flx-sutainbility-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 67px 111px;
    position: absolute;
    bottom: 40px;
    right: 0;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #flx-sutainbility-section::before {
    content: "";
    background-image: url(../object/orange-hero.png);
    padding: 66px 80px;
    position: absolute;
    bottom: 62px;
    left: -46px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /*===========================================================sutainability page end media query ================================*/
  /*===========================================================contact page start media query ========================================*/

  .flx-hero-textsection {
    height: 450px;
    padding: 40px 0px;
  }

  /*===========================================================contact page end media query ========================================*/
}

/* X-Large devices (large desktops, 1200px and up) */

/* Large devices (desktops, 992px and up) */
@media (max-width: 991px) {
  .flx-rdetailed-leaves > span::after {
    right: -10%;
    left: 100%;
  }
  #flx-taste-smoothies {
    position: relative;
    padding: 0px 0px 120px 0px;
  }
  .svg-container {
    width: 300px !important;
    height: 350px !important;
    resize: both;
  }
  .h-md-900 {
    height: 900px;
  }
  .flx-animate-img {
    top: 186px;
  }

  .flx-navbar-logo img {
    position: absolute;
    width: 50px;
    /* right: 0; */
    top: 5px;
    /* left: 0px; */
  }

  .flx-social-media {
    padding: 0px 0px 0px 0px;
  }

  .flx-mobile-toggle {
    float: right;
    margin: 0px 0px 0px 0px;
    position: relative;
  }

  /* hero section */
  h1 {
    font-size: 30px;
    line-height: 34px;
  }

  .flx-stock {
    font-size: 56px;
    line-height: 56px;
    -webkit-text-stroke-width: 0.5px;
    opacity: 0.75;
    top: -38px;
  }

  .hsn-preline {
    white-space: inherit;
    padding: 0px 15px;
  }

  #flx-here-section {
    background-position: center;
  }

  .flx-slider-top {
    padding: 70px 0px 0px 0px;
  }

  .flx-text-col {
    height: 520px;
    padding: 0px 0px;
  }

  #flx-here-section::before {
    padding: 140px 129px;
    bottom: 15px;
  }

  .flx-dots-slider {
    bottom: 50px !important;
    width: 85px !important;
    left: 12% !important;
  }

  .flx-prevslider-btn img,
  .flx-nextslider-btn img {
    width: 26px;
  }

  .flx-slider-controller {
    width: 66%;
    bottom: 140px;
    left: 0px;
  }

  .flx-dots-style {
    width: 100% !important;
    max-width: 120px !important;
    height: 100% !important;
    background-color: transparent !important;
    margin: 0px 6px !important;
  }

  /* hero section end*/

  /* our-boxes start*/

  #flx-products-box {
    padding: 80px 0px;
    position: relative;
  }

  #flx-products-box::before {
    content: "";
    background-image: url(../img/orange-object.png);
    padding: 68px;
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* overlay style */
  .flx-overlay-container {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .flx-box-bg:hover .flx-overlay-container {
    opacity: 1;
    width: 100%;
  }

  .flx-overlay-container:after {
    content: "";
    background-image: url(../object/water-object.png);
    padding: 90px;
    position: absolute;
    bottom: -42px;
    background-size: cover;
    background-repeat: no-repeat;
    right: 0;
    left: 0;
  }

  .flx-box-bg:hover::before {
    content: "";
    background-image: url(../object/heart-object.png);
    padding: 10px 11px;
    position: absolute;
    top: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    right: 20px;
  }

  /* overlay style */
  .flx-box-bg {
    position: relative;
    margin: 0;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
  }

  .flx-box-bg:hover {
    background-color: #fff;
  }

  .flx-box-bg:hover .flx-hide-title {
    opacity: 0;
  }

  .flx-box-bg:hover .flx-hide-paragh {
    opacity: 0;
  }

  .flx-box-btn {
    background-color: var(--green) !important;
    padding: 8px 20px !important;
    border: none !important;
  }

  .flx-box-btn:hover {
    background-color: var(--green);
    padding: 8px 20px;
  }

  /* overlay style */
  .hsn-box-bg:hover {
    background-color: #fff;
    /* height: 280px; */
  }

  .flx-overlay-container {
    bottom: 5%;
  }

  .flx-overlay-container:after {
    bottom: -34px;
  }

  /* overlay style */
  /* image and text section name vision  recipes start*/
  .flx-vision-leave:after {
    top: -10px;
    right: 20px;
  }

  .flx-vision-fruite::after {
    content: "";
    background: url(../object/vision-fruits.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 66px 111px;
    z-index: 50;
    bottom: -33px;
    left: -50px;
  }

  .flx-recipes-object::after {
    content: "";
    background: url(../object/SmoothieMixer.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 87px 90px; */
    z-index: 50;
    bottom: -20px;
    right: -50px;
    width: 300px;
    height: 200px;
  }

  .flx-width-icon {
    width: 60px;
  }

  /* image and text section name vision  recipes end*/
  .flx-bg-footer::before {
    content: "";
    background-image: url(../img/footer_top_pic.png);
    /* padding: 182px 94px; */
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 15px;
    bottom: -80px;
    width: 250px;
    z-index: 49;
    height: 250px;
  }

  .flx-bg-footer::after {
    content: "";
    background-image: url(../object/leave.png);
    padding: 42px 46px;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: -95px;
    z-index: 49;
    right: 28px;
  }

  /*===========================================================about page start media query ========================================*/

  .flx-desktop {
    display: none;
  }

  .flx-mobile {
    display: block;
  }

  /* hero section */
  h1 {
    font-size: 30px;
    line-height: 34px;
  }

  .flx-hero-about {
    height: 450px;
    padding-top: 50px;
  }

  #flx-hero-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 80px 135px;
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* hero section end*/
  /* about section */
  .flx-about-stock {
    font-size: 58px;
    line-height: 58px;
    color: #ffffff00;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--green);
    z-index: 0;
    position: absolute;
    top: -38px;
    left: 0px;
  }

  /* .flx-forever-object:before {
    content: "";
    background-image: url(../object/about-forever.png);
    padding: 96px 101px;
    position: absolute;
    top: -75px;
    left: 14px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  } */
  .flx-forever-object .disk-container {
    width: 150px;
    height: 150px;

    top: -10px;
    left: 0px;
  }

  .flx-about-col::after {
    content: "";
    background-image: url(../object/leave-2.png);
    padding: 20px 31px;
    position: absolute;
    top: -65px;
    right: 100px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .flx-forever-object {
    position: relative;
    padding: 50px 0px 0px 0px;
  }

  #flx-about {
    padding: 120px 0px 120px 0px;
    position: relative;
  }

  /* about section */
  /* icon box section how we make */
  .flx-iconbox-about-mobile img {
    width: 100px;
  }

  .flx-imgbox {
    width: 100%;
    max-width: 650px;
    margin: auto;
  }

  #flx-second-imgbox::before {
    content: "";
    background-image: url(../object/smoothies-object.png);
    padding: 130px 70px;
    position: absolute;
    left: 0;
    bottom: -40px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .flx-add-leaveobject::after {
    content: "";
    background-image: url(../object/orange-object.png);
    padding: 38px 50px;
    position: absolute;
    right: 0;
    top: -84px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  /* icon box section how we make end*/
  /*===========================================================about page end media query ========================================*/
  /*===========================================================sutainability page start media query ================================*/
  .flx-hero-sutainbility {
    height: 430px;
    padding: 80px 0px;
  }

  .flx-hero-sutainbility p {
    width: 98%;
    margin: auto;
  }

  #flx-sutainbility-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 77px 127px;
    position: absolute;
    bottom: 49px;
    right: 0;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /*===========================================================sutainability page start media query ================================*/
  /* ==========================================================whyindivit page start media query ===================================*/
  .flx-display-none {
    display: none !important;
  }

  /* ==========================================================whyindivit page end media query ===================================*/

  /*===========================================================boxui page start media query ========================================*/

  #flx-icon-box::before {
    content: "";
    background-image: url(/src/assets/object/vision-fruits.png);
    padding: 58px 102px;
    right: 0;
    bottom: -40px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #flx-icon-box::after {
    content: "";
    background-image: url(/src/assets/object/smoothies-object.png);
    padding: 103px 64px;
    bottom: -30px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
  }

  /*===========================================================boxui page end media query ========================================*/
}

/* medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .header_bg-svg {
    max-height: 750px;
  }
  .flx-rdetailed-leaves > span::after {
    right: 5%;
    left: 90%;
  }
  .svg-container {
    width: 500px !important;
    height: 550px !important;
    resize: both;
  }
  .flx-two-relative {
    position: relative;
  }

  .flx-twoabsolute {
    position: initial;
    margin-top: -65px;
  }

  .flx-animate-relative {
    position: relative;
  }

  .flx-oneabsolute {
    position: initial;
    display: none;
  }

  .flx-animate {
    animation: none;
  }

  .flx-animate-img {
    top: 376px;
    animation: none;
  }

  /* #flx-here-section:has(.carousel-item.slide-1.active) {
    background-image: url(../img/mobile-hero1.png) !important;
  }

  #flx-here-section:has(.carousel-item.slide-2.active) {
    background-image: url(../img/mobile-hero2.png) !important;
  }

  #flx-here-section:has(.carousel-item.slide-3.active) {
    background-image: url(../img/mobile-hero3.png) !important;
  } */

  /* animate */
  .flx-mobile-toggle {
    float: right;
    margin: 0px 0px 0px 0px;
    position: relative;
  }

  /* hero section */
  #flx-here-section {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* padding: 90px 0px 0px 0px; */
  }

  .flx-stock {
    font-size: 56px;
    line-height: 54px;
    -webkit-text-stroke-width: 0.5px;
    opacity: 0.75;
    top: -36px;
  }

  .hsn-preline {
    white-space: inherit;
    padding: 0px 30px;
  }

  .flx-slider-top {
    padding-top: 60px;
    padding-bottom: 200px;
  }

  .flx-slider-top img {
    width: 370px;
  }

  #flx-here-section::before {
    content: "";
    background-image: url(../img/mask-leafe.png);
    padding: 130px 109px;
    position: absolute;
    bottom: 30px;
    left: 0px;
    /* z-index: 50; */
    background-repeat: no-repeat;
    background-size: cover;
  }

  .flx-slider-controller {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    bottom: 145px;
    left: 8px;
  }

  .flx-dots-slider {
    right: 0% !important;
    bottom: 115px !important;
    z-index: 50 !important;
    width: 70px !important;
    left: 30% !important;
  }

  .flx-text-col {
    height: 300px;
  }

  /* hero section end */
  /* overlay style */
  .hsn-box-bg:hover {
    /* height: 350px; */
  }

  .flx-width-icon {
    width: 60px;
  }

  /* overlay style end*/
  /* slider owl-carousel start */
  .slider .prev-slide {
    left: 0px;
    top: 40%;
  }

  .slider .next-slide {
    right: 0;
    top: 40%;
  }

  /* slider owl-carousel start */
  /* image and text section name vision  recipes start */
  .flx-vision-leave:after {
    top: -8px;
    right: -14%;
  }

  .flx-vision-fruite::after {
    content: "";
    background: url(../object/vision-fruits.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 85px;
    z-index: 50;
    bottom: -30px;
    left: 0;
  }

  .flx-recipes-object::after {
    content: "";
    background: url(../object/SmoothieMixer.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 58px 60px; */
    right: 10px;
    bottom: -10px;
    z-index: 50;
    width: 200px;
    height: 134px;
  }

  .flx-text-left {
    padding: 0px 0px 0px 0px;
  }

  .flx-recipes-object {
    padding-right: 50px;
  }

  .flx-vision-fruite {
    position: relative;
    padding-left: 50px;
  }

  /* image and text section name vision recipes end */

  .flx-bg-footer::before {
    /* padding: 101px 52px; */
    left: 0px;
    bottom: -50px;
    width: 170px;
    height: 170px;
  }

  .flx-bg-footer::after {
    content: "";
    background-image: url(../object/leave.png);
    padding: 42px 46px;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: -83px;
    z-index: 49;
    right: 0px;
  }

  .flx-topfooter-height {
    padding-bottom: 76px;
  }

  #flx-upper-footer {
    margin: 50px 0px 0px 0px;
  }

  .flx-upperfooter-height {
    height: 100%;
    min-height: 450px;
  }

  #flx-footer {
    padding: 50px 0px 0px 0px;
  }

  /*===========================================================about page start media query ========================================*/
  /* hero section */
  .flx-hero-about {
    height: 450px;
    padding-top: 40px;
  }

  .flx-hero-about p {
    width: 100%;
    margin: auto;
  }

  #flx-hero-section {
    height: 225px;
    position: relative;
    background-image: url(../img/sustain-banner-bg-2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  #flx-hero-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 70px 115px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #flx-hero-section::before {
    content: "";
    background-image: url(../object/orange-hero.png);
    padding: 66px 80px;
    position: absolute;
    bottom: 30px;
    left: -32px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* hero section end */
  /* about section */
  #flx-about {
    padding: 120px 0px 120px 0px;
    position: relative;
  }

  .flx-forever-object {
    position: relative;
    padding: 100px 0px 0px 0px;
  }

  /* .flx-forever-object:before {
    content: "";
    background-image: url(../object/about-forever.png);
    padding: 100px 106px;
    position: absolute;
    top: 18px;
    left: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  } */

  .flx-about-col::after {
    content: "";
    background-image: url(../object/leave-2.png);
    padding: 20px 31px;
    position: absolute;
    top: -66px;
    right: 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* about section */
  /*===========================================================about page start media query ========================================*/
  /*===========================================================sutainability page start media query ================================*/
  .flx-hero-sutainbility {
    height: 430px;
    padding: 70px 0px;
  }

  .flx-hero-sutainbility p {
    width: 98%;
    margin: auto;
  }

  #flx-sutainbility-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 67px 111px;
    position: absolute;
    bottom: 40px;
    right: 0;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #flx-sutainbility-section::before {
    content: "";
    background-image: url(../object/orange-hero.png);
    padding: 66px 80px;
    position: absolute;
    bottom: 62px;
    left: -46px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /*===========================================================sutainability page start media query ================================*/

  /*===========================================================contact page start media query ========================================*/
  .contant-info {
    padding: 90px 0px 0px 15px;
  }

  .flx-hero-textsection p {
    width: 100%;
    margin: auto;
    padding: 10px 0px 10px 0px;
  }

  /*===========================================================contact page end media query ========================================*/
  /*===========================================================boxui page start media query ========================================*/

  .flx-boxuicon ul {
    display: flex;
    text-decoration: none;
    list-style: none;
    padding: 0px;
  }

  .flx-boxuicon img {
    width: 25px;
  }

  /*===========================================================boxui page end media query ========================================*/
}

/*Small devices end (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .flx-rdetailed-leaves > span::after {
    right: 5%;
    left: 85%;
    display: none;
  }
  .svg-container {
    width: 450px !important;
    height: 550px !important;
    resize: both;
  }

  /* logo */
  .flx-navbar-logo img {
    position: absolute;
    width: 50px;
    /* right: 0; */
    top: 5px;
    /* left: -10px; */
  }

  /* hero section */
  .flx-stock {
    font-size: 48px;
    line-height: 48px;
    top: -30px;
  }

  .flx-text-col {
    height: 330px;
    padding: 0px 10px 0px 10px;
  }

  .hsn-preline {
    white-space: inherit;
    padding: 0px 15px;
  }

  .flx-prevslider-btn img,
  .flx-nextslider-btn img {
    width: 25px;
  }

  .flx-dots-slider {
    bottom: 60px !important;
    width: 60px !important;
    left: 35% !important;
  }
  .flx-slider-controller {
    bottom: 85px;
  }

  /* hero section end*/
  /* overlay style */
  .hsn-box-bg:hover {
    /* height: 420px; */
  }

  /* image and text section name vision  recipes start */
  .flx-vision-leave:after {
    top: -8px;
    right: 0%;
  }

  .flx-text-left {
    padding: 40px 0px 0px 0px !important;
  }

  .flx-vision-fruite::after {
    content: "";
    background: url(../object/vision-fruits.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 52px 92px;
    z-index: 50;
    bottom: 0;
    left: 0;
  }

  .flx-recipes-object::after {
    content: "";
    background: url(../object/SmoothieMixer.png);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 72px 70px; */
    right: 10px;
    bottom: 0px;
    z-index: 50;
  }

  /* image and text section name vision  recipes end */
  /* topfooter styling */

  .flx-topfooter-height {
    padding: 25px 0px 50px 0px;
  }

  .flx-bg-footer::after {
    padding: 25px 27px;
    top: -40px;
    right: 30px;
  }

  .flx-bg-footer::before {
    /* padding: 80px 41px; */
    left: -25px;
    bottom: -60px;
    width: 160px;
    height: 161px;
  }

  #flx-footer {
    padding: 130px 0px 0px 0px;
  }

  /*===========================================================about page start media query ========================================*/

  /* hero section */
  .flx-hero-about p {
    width: 100%;
    margin: auto;
  }

  .flx-hero-about {
    height: 450px;
    padding-top: 40px;
  }

  #flx-hero-section {
    height: 220px;
    position: relative;
    background-image: url(../img/sustain-banner-bg-2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  #flx-hero-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 40px 64px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #flx-hero-section::before {
    content: "";
    background-image: url(../object/orange-hero.png);
    padding: 50px 60px;
    position: absolute;
    bottom: 0;
    left: -30px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .flx-about-stock {
    font-size: 55px;
    line-height: 55px;
    color: #ffffff00;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--green);
    z-index: 0;
    position: absolute;
    top: -34px;
    left: 4px;
  }

  .flx-about-col::after {
    content: "";
    background-image: url(../object/leave-2.png);
    padding: 20px 31px;
    position: absolute;
    top: -60px;
    right: 64px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* hero section end*/

  /* about style */
  .flx-forever-object {
    position: relative;
    padding-top: 100px;
  }

  /* .flx-forever-object:before {
    content: "";
    background-image: url(../object/about-forever.png);
    padding: 88px 88px;
    position: absolute;
    top: 0;
    left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  } */

  .flx-forever-object .disk-container {
    top: 10px !important;
    left: 0px;
    width: 120px !important;
    height: 120px !important;
    transform: scale(0.8);
  }

  #flx-about:after {
    content: "";
    background-image: url(../object/Green-about.png);
    padding: 55px 48px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .flx-text-left img {
    width: 70px;
  }

  /* about style */
  /* full width section text How we make our smoothies start */
  #flx-second-imgbox::before {
    display: none;
  }

  .flx-img-align {
    text-align: center !important;
  }

  /*===========================================================about end start media query ========================================*/

  /*===========================================================sutainability page start media query ================================*/
  .flx-hero-sutainbility {
    height: 430px;
    padding: 0px 0px;
  }

  .flx-hero-sutainbility p {
    width: 98%;
    margin: auto;
  }

  .flx-hero-faq p {
    width: 97%;
    margin: auto;
  }

  .flx-hero-faq {
    height: 450px;
    padding: 175px 0px;
  }

  /*===========================================================sutainability page start media query ================================*/
  /*===========================================================contact page start media query ========================================*/
  #flx-contact-section {
    height: 580px;
    position: relative;
    background-image: url(../img/contact-banner-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .flx-hero-textsection p {
    width: 95%;
    margin: auto;
    padding: 10px 0px;
  }

  #flx-contact-section::after {
    content: "";
    background-image: url(../object/vision-fruits.png);
    padding: 50px 84px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #flx-contact-section::before {
    content: "";
    background-image: url(../object/orange-hero.png);
    padding: 56px 68px;
    position: absolute;
    bottom: 0;
    left: -60px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .contact-wrap {
    padding: 0px 0px 0px 0px;
  }

  #contant {
    background-color: transparent;
    padding: 100px 20px 20px 20px;
  }

  .form-control {
    padding: 15px 25px;
  }

  .contant-info {
    padding: 50px 10px;
  }

  .flx-iconbox img {
    width: 100px;
  }

  .flx-location {
    width: 115px !important;
  }

  .flx-email {
    width: 42px !important;
  }

  .flx-phone {
    width: 42px !important;
  }

  /*===========================================================contact page end media query ========================================*/
  .flx-selectbox-style {
    width: 295px;
    height: 60px;
    padding: 0px 20px;
    border-radius: 8px;
  }

  /*===========================================================faqs page start media query ========================================*/
  #flx-faqs-section::after {
    content: "";
    background-image: url(/src/assets/object/vision-fruits.png);
    padding: 48px 82px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .flx-hero-faq::after {
    content: "";
    background-image: url(/src/assets/object/leave-single.png);
    padding: 20px 31px;
    position: absolute;
    bottom: 150px;
    left: 35%;
    right: 50%;
    z-index: 50;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/*Small devices end (landscape phones, 576px and up) */

/* @media full width section Sustainability start */

@media (min-width: 550px) and (max-width: 850px) {
  .flx-icon-box-col {
    padding: 0px 0px 0px 50px;
  }
}

@media (max-width: 449px) {
  .flx-rdetailed-leaves > span::after {
    right: 15%;
    left: 75%;
    display: block;
  }
  .flx-icon-box-col {
    padding: 0px 20px 0px 20px;
  }
  .svg-container {
    width: 350px !important;
    height: 420px !important;
    resize: both;
  }

  .flx-recipes-bg {
    height: 450px;
  }

  /* @media full width section Sustainability end */

  /* image and text section name vision & recipes start */

  .flx-text-left {
    padding: 0px 0px 0px 0px;
  }
}

/* image and text section name vision end */

/* Don't just make a smoothie. flx-upper-footer start */
@media (max-width: 400px) {
  .flx-recipes-bg {
    height: 380px;
  }
}

@media (max-width: 768px) {
  .flx-forever-object .disk-container {
    width: 150px;
    height: 150px;
    top: -10px;
    left: 0px;
  }
  #flx-upper-footer {
    margin: 50px 0px 0px 0px;
  }

  .flx-upperfooter-height {
    height: 100%;
    min-height: 450px;
  }
  #flx-icon-box::after {
    content: "";
    background-image: url(/src/assets/object/smoothies-object.png);
    padding: 103px 64px;
    bottom: -75px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
  }
}

@media (max-width: 576px) {
  #flx-upper-footer {
    margin: 50px 0px;
  }

  .flx-upperfooter-height {
    height: 100%;
    min-height: 350px;
  }
  #flx-icon-box::after {
    content: "";
    background-image: url(/src/assets/object/smoothies-object.png);
    padding: 70px 40px;
    bottom: -70px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
  }
  #flx-icon-box::before {
    padding: 50px 85px;
  }
}

/* Don't just make a smoothie. flx-upper-footer end */

/* Styles By Tauqeer Abbas Start*/

.offcanvas-body {
  overflow-x: hidden;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
}

.carousel-button-group {
  top: calc(50% - 66px);
}

.loader {
  background-color: #35920073;
  position: fixed;
  z-index: 999;
}

.loader img {
  position: absolute;
  left: 46%;
  top: 40%;
}
.h-410 {
  height: 410px;
}
.max-h-410 {
  max-height: 410px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-460 {
  max-height: 460px !important;
}
.max-h-350 {
  max-height: 350px !important;
}
.max-h-600 {
  max-height: 600px !important;
}
.min-h-500 {
  min-height: 500px;
}
.min-h-410 {
  min-height: 410px;
}
.min-h-105 {
  min-height: 105px;
}

.h-495 {
  height: 495px;
}
.h-378 {
  height: 378px;
}
.h-396 {
  height: 396px;
}
.w-100px {
  width: 100px;
}
.w-200px {
  width: 200px;
}

.nowrap {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--green) !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--green) !important;
}

/* View All Ingredients Tabs */
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #ffffff #fff var(--green) !important;
  isolation: isolate;
  /* border-width: 2px; */
}

.carousel-indicators {
  margin-left: 5% !important;
}

.h-840 {
  height: 840px;
}

.top-25 {
  top: 25% !important;
}
.top-110px {
  top: 110px;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.progress-btn-bar {
  position: absolute !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  background-color: var(--green) !important;
  border-radius: 6px;
  z-index: -1;
}

.progress-btn {
  padding: 10px 25px !important;
  background-color: transparent !important;
  /* color: var(--green) !important; */
  border: 1px solid var(--green) !important;
  border-radius: 8px !important;
  min-width: 300px;
  /* color: #000000 !important; */
  -webkit-filter: invert(1);
  filter: invert(1);
  mix-blend-mode: difference;
}

/* Quantity Range Slider Start */
.single-thumb .range-slider__thumb[data-lower],
.single-thumb .range-slider__thumb[data-upper] {
  width: 0;
}

.range-slider {
  height: 20px !important;
  background: transparent !important;
  border: 1px solid var(--green);
}

.range-slider .range-slider__range {
  background: var(--green) !important;
}

.btn-solid-success {
  padding: 10px 25px !important;
  background-color: var(--green) !important;
  color: var(--white) !important;
  border: 1px solid var(--green) !important;
  border-radius: 8px !important;
}

.btn-outline-light {
  padding: 10px 25px !important;
  box-shadow: none !important;
}

.btn-outline-light:hover {
  color: var(--green) !important;
}

.text-theme-success {
  color: var(--green) !important;
  text-decoration: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--green) !important;
}

.fs-18 {
  font-size: 18px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-8 {
  font-size: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.rounded-8 {
  border-radius: 8px !important;
}

.border-theme-success {
  border-color: var(--green) !important;
  border: 1px solid;
}
.hover-green:hover {
  color: var(--green) !important;
}
.h-75vh {
  height: 75vh;
}
.max-h-50vh {
  max-height: 50vh;
}
/* .navbar .nav-item:hover .dropdown-menu {
  display: block;
  width: 100%;
  left: 0;
  top: 93px;
} */

.cursor-pointer {
  cursor: pointer;
}
.pe-disable {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
.cursor-copy {
  cursor: copy !important;
}
.border-bottom-3 {
  border-bottom: 3px solid var(--green) !important;
}
.start-75 {
  left: 75%;
}
.end-22px {
  right: 22px;
}
.start-25 {
  left: 25% !important;
}
.start-10 {
  left: 10% !important;
}
.start-25px {
  left: 25px !important;
}
.pt-140 {
  padding-top: 140px;
}
.fs-14 {
  font-size: 14px !important;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #6c757d90;
  z-index: 9999;
}
.svg-container {
  width: 500px;
  height: 550px;
  resize: both;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 9999;
}

/* Navbar SHOP Background Color and Wave animation  */
.box-bg-0 {
  background: transparent linear-gradient(180deg, #fc4a1a 0%, #fc4a1a33 100%) 0%
    0% no-repeat padding-box;
}

.box-bg-1 {
  background: transparent
    linear-gradient(180deg, #9fbe6a 0%, #9fbe6a61 100%, #e17777 100%) 0% 0%
    no-repeat padding-box;
}

.box-bg-2 {
  background: transparent
    linear-gradient(180deg, #fdcf54 0%, #fdcf5461 100%, #e17777 100%) 0% 0%
    no-repeat padding-box;
}

.box-bg-3 {
  background: transparent
    linear-gradient(180deg, #fd9c2b 0%, #fdcf5461 100%, #e17777 100%) 0% 0%
    no-repeat padding-box;
}

.box-btn-bg-0 {
  background-color: #fc4a1a !important;
}

.box-btn-bg-1 {
  background-color: #9fbe6a !important;
}

.box-btn-bg-2 {
  background-color: #fdcf54 !important;
}

.box-btn-bg-3 {
  background-color: #fd9c2b !important;
}

.z-index-10 {
  z-index: 10;
  position: relative;
}

.form-check-input:checked {
  background-color: var(--green) !important;
  border-color: var(--green) !important;
}
.dropdown-menu .dropdown-item:hover {
  background-color: var(--green) !important;
  color: white !important;
}
.bg-theme-success {
  background-color: var(--green) !important;
}

.dropdown-toggle:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
.input-label {
  font-size: 10px;
  position: absolute;
  background-color: white;
  left: 26px;
  top: -7px;
  padding: 0 5px;
}
.img-fluid {
  object-fit: contain !important;
}
.card-img-top {
  height: 300px !important;
}

/* Hide the default checkbox */
#invoicingCheckbox input[type="checkbox"] {
  display: none;
}

/* Style the label to act as the checkbox */
#invoicingCheckbox label {
  display: inline-block;
  position: relative;
  padding-left: 30px; /* Adjust the padding as needed */
  cursor: pointer;
}

/* Style the custom checkbox */
#invoicingCheckbox label:before {
  font-family: "FontAwesome";
  content: "\f05d";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
}

/* Style the custom checkbox when checked */
#invoicingCheckbox input[type="checkbox"]:checked + label:before {
  content: "\f058";
  color: var(--green);
}

/* Card Bages  */

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ribbon__content {
  right: -5px;
  top: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  display: block;
  width: 210px;
  padding: 5px 0;
  background-color: var(--green);
  box-shadow: 0 0px 10px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  /* border: 2px dotted #fff; */
  /* outline : 5px solid  #f5cd79; */
}

/* Card Bages  */

/* Menu Wave Effect start */

.wave-container .waves {
  position: relative;
  width: 100%;
  margin-bottom: -7px; /*Fix for safari gap*/
  height: 0px;
  transition: all 500ms ease-out;
}

.box-bg-0:hover .wave-container .waves {
  height: 150px;
  transition: all 1s ease-out;
}
.box-bg-1:hover .wave-container .waves {
  transition: all 1s ease-out;
  height: 150px;
}
.box-bg-2:hover .wave-container .waves {
  transition: all 1s ease-out;
  height: 150px;
}
.box-bg-3:hover .wave-container .waves {
  transition: all 1s ease-out;
  height: 150px;
}

/* Animation */

.wave-container .parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.wave-container .parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.wave-container .parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.wave-container .parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.wave-container .parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .wave-container .waves {
    height: 40px;
    min-height: 40px;
  }
}

@media (min-width: 1300px) {
  .custom-container {
    max-width: 1320px !important;
  }
  .header_bg-svg {
    max-height: 900px;
  }
}
/* Quantity Range Slider Start */
@media (min-width: 1024px) {
  #flx-hero-section {
    height: 350px;
  }
}
@media (min-width: 992px) {
  /* #flx-hero-section {
    height: 300px;
  } */
  .position-lg-initial {
    position: initial !important;
  }
  .header_bg-svg {
    min-height: 900px;
  }

  .transform-lg-none {
    transform: none !important;
  }

  .border-lg-none {
    border: none !important;
  }

  .offcanvas-end {
    width: 41.66666667% !important;
  }
}

.rotate {
  animation: rotation 2s infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/* Tooltip Content */
.tooltip-content {
  /* display: none; */
  position: absolute;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  /* // High z-index to ensure it appears above other elements */
  margin-bottom: 10px;
  /* // Space between the tooltip and the triggering element */
}

.tooltip-content::after {
  content: "";
  position: absolute;
  bottom: -10px;
  /* // Position the arrow at the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #333;
  /* // Arrow pointing downwards */
}
.fw-500 {
  font-weight: 500;
}

@media (min-width: 768px) {
  .header_bg-svg {
    max-height: 750px;
  }
  .custom-md-col {
    flex: 0 0 20% !important; /* 100% / 5 columns */
    max-width: 20% !important; /* 100% / 5 columns */
  }
  .w-max-content {
    width: max-content;
  }
  .max-w-md {
    max-width: 768px !important;
  }
}

.bottom-10px {
  bottom: 10px;
}
.z-60 {
  z-index: 60;
}

.page-item.active .page-link {
  background-color: var(--green) !important;
  border-color: var(--green) !important;
}
.pagination .page-link {
  color: var(--green);
}
/* Testimonials */

#testimonials .react-multi-carousel-dot--active button {
  background: var(--green);
  border-color: var(--green);
  width: 35px;
  border-radius: 8px;
  transition: 0.3s ease;
}
#testimonials
  .react-multi-carousel-dot:not(.react-multi-carousel-dot--active)
  button {
  border-color: var(--green);
  background: var(--green-opacity);
  transition: 0.3s ease;
}
/* Testimonials End */
/* ============================== Styles By Tauqeer Abbas END ====================================== */

.html-blog a {
  color:#417600 !important;
  font-weight: bold !important;

}