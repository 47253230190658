

*,*:before,*:after {
    box-sizing:border-box;
  }
  
  .page-wrapper {
    min-height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    button {
      position:relative;
      .cart-item {
        position:absolute;
        height:24px;
        width:24px;
        top:-10px;
        right:-10px;
        &:before {
          content:'1';
          display:block;
          line-height:24px;
          height:24px;
          width:24px;
          font-size:12px;
          font-weight:600;
          background:#2bd156;
          color:white;
          border-radius:20px;
          text-align:center;
        }
      }
      &.sendtocart {
        .cart-item {
          display:block;
          animation: xAxis 1s forwards cubic-bezier(1.000,0.440,0.840,0.165);
          &:before {
            animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.840, 0.440, 1.000);
          }
        }
      }
    }
  }

  .sendtocart-2 {
    display:block;
    transition: transform 1s ease-out;
    /* animation: xAxis 1s forwards cubic-bezier(1.000,0.440,0.840,0.165); */
    /* &:before {
      animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.840, 0.440, 1.000);
    } */
  }
  .cart-item-anim {
    position:absolute;
    height:24px;
    width:24px;
    top:-10px;
    right:-10px;
    &:before {
      content:'1';
      display:block;
      line-height:24px;
      height:24px;
      width:24px;
      font-size:12px;
      font-weight:600;
      background:var(--green);
      color:white;
      border-radius:20px;
      text-align:center;
    }
  }
  
  
 .shake {
      animation: shakeCart .4s ease-in-out forwards;
    }
  
  @keyframes xAxis {
    100% {
      transform: translateX(calc(50vw - 105px));
    }
  }
  
  @keyframes yAxis {
    100% {
      transform: translateY(calc(-50vh + 75px));
    }
  }
  
  @keyframes shakeCart {
    25% {
      transform:translateX(6px)
    }
    50% {
      transform:translateX(-4px);
    }
    75% {
      transform:translateX(2px);
    }
    100% {
      transform:translateX(0);
    }
  }