#circle-container {
    transform: rotateX(75deg);
    width: 80%;
    /* height: 80%; */
    /* margin-top: 40px; */
    animation: increaseCircleHeight 5s linear forwards

  
  }
  @keyframes increaseCircleHeight {
    0% {
        height: 0%;  /* Starting height */
       display: none;
    }
    40% {
        height: 0%;  /* Starting height */
       display: none;
       margin-top: 300px;
    }
    70% {
        height: 0%;  /* Starting height */
       display: none;
       margin-top: 200px;
    }
    100% {
        height: 80%;  /* Ending height */
        margin-top: 40px;
    }
}
.blender-container {
      width: 400px;
      height: 450px;
    }
  

  .circle {
    margin-top: -100px;
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
    /* box-shadow: 1px 25px 0px 0px rgba(255, 49, 49, 0.462) inset,
      5px 10px 0px 50px rgba(251, 49, 49, 0) inset; */
    animation: spin 1s infinite linear, opacity 5s linear;
    -moz-animation: spin 1s infinite linear, opacity 5s linear;
    -webkit-animation: spin 1s infinite linear, opacity 5s linear;
    z-index: 1;
    background: rgba(255, 31, 31, 0);
  }
  @keyframes opacity {
    0% {
     opacity: 0;
     background-color: transparent;
    }
    30% {
      opacity: 0;
      background-color: transparent;
     }
    100% {
     opacity: 1;
     background-color: rgba(255, 31, 31, 0);;
    }
  }
  
  
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
    }
  
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spinoff {
    0% {
      -moz-transform: rotate(0deg);
    }
  
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spinoff {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  
  /* waves */
  .ocean {
    height: 70px; /* change the height of the waves here */
    width: 100%;
    position: absolute;
    /* top: 0; */
    left: 0;
    /* bottom:0; */
    right: 0;
    overflow-x: hidden;
  }
  
   /* .wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 1s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    display: none;
  } */
   
  /* .wave2 {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='yellow'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 1s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  } */
  /* .wave2:nth-of-type(2) {
    bottom: 0;
    transform: rotate(180deg);
    animation: wave 1s -3s linear reverse infinite;
    opacity: 0.5;
  } */
  
  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-25%);
    }
    50% {
      transform: translateX(-50%);
    }
    75% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes increaseHeight {
    from {
        height: 0%;  /* Starting height */
    }
    to {
        height: 100%;  /* Ending height */
    }
}
  @keyframes moveToTop {
    0% {
        margin-top:0%;
        /* height: 10%; Starting height */
    }
    100% {
        margin-top: -15%;
        /* height: 10%;  Ending height */
    }
}
  
  .jug-clip {
    clip-path: polygon(5% 0, 95% 0, 80% 100%, 20% 100%);
  }
  .jugBottom{
    clip-path: polygon(25% 0%, 70% 0%, 100% 30%, 100% 65%, 0 65%, 0% 30%);
    background-color: rgb(0, 0, 0);
    height: 180px;
    /* width: 100%; */
  }
  .mixer-img{
    width: 300px;
  }
  .juice-layer{
    width: 240px;
    height: 83%;
  }
  .fruit-layer{
    width: 240px;
    height: 405px;
  }
  .min-h-blender{
    min-height: 650px;
  }
  @media (max-width: 1399px) {
    .mixer-img{
      width: 250px;
    }
    .fruit-layer{
      width: 200px;
      height: 340px;
    }
    .juice-layer{
      width: 200px;
    }
  }

  @media (max-width: 1199px) {
    .blender-container {
      width: 300px;
      height: 350px;
    }
    .mixer-img{
      width: 220px;
    }
    .juice-layer{
      width: 180px;
      height: 83%;
    }
    .fruit-layer{
      width: 180px;
      height: 300px;
    }
    .min-h-blender{
      min-height: 500px;
    }
    
  }
  @media (max-width: 991px) {
    .blender-container {
      width: 300px;
      height: 350px;
    }
    .mixer-img{
      width: 160px;
    }
    .juice-layer{
      width: 130px;
      height: 83%;
    }
     .fruit-layer{
      width: 130px;
      height: 215px;
    }
    .min-h-blender{
      min-height: 400px;
    }
    
  }
  @media (max-width: 767px) {
    .blender-container {
      width: 400px;
      height: 450px;
    }
    .mixer-img{
      width: 240px;
    }
    .juice-layer{
      width: 200px;
      height: 83%;
    }
    .fruit-layer{
      width: 200px;
      height: 325px;
    }
    .min-h-blender {
      min-height: 620px;
  }
  
  }
  @media (max-width: 576px) {
    .blender-container {
      width: 350px;
      height: 400px;
    }
    .mixer-img{
      width: 200px;
    }
    .juice-layer{
      width: 162px;
      height: 83%;
    }
    .fruit-layer{
      width: 162px;
      height: 272px;
    }
    .min-h-blender {
      min-height: 600px;
  }
  }
  @media (max-width: 449px) {
    .blender-container {
      width: 300px;
      height: 350px;
    }
    .mixer-img{
      width: 165px;
    }
    .juice-layer{
      width: 135px;
      height: 83%;
    }
    .fruit-layer{
      width: 135px;
      height: 225px;
    }
    .min-h-blender {
      min-height: 500px;
  }
  }


  