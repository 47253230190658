.base-timer {
    position: relative;
    width: 50px;
    height: 50px;
  }
  
  .base-timer__svg {
    transform: scaleX(-1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 1px;
    stroke: grey;
  }
  
  .base-timer__path-remaining {
    stroke-width: 5px;
    /* stroke-linecap: round; */
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.green {
    color: rgb(65, 184, 131);
  }
  
  .base-timer__path-remaining.orange {
    color: green;
  }
  
  .base-timer__path-remaining.red {
    color: red;
  }
  
  .base-timer__label {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    flex-direction: column;
    font-family: 'Epilogue', sans-serif;
  }
  @media (max-width: 1199px) {
    .base-timer {
       width: 70px;
      height: 70px;
    }
    .base-timer__label {
      position: absolute;
      width: 70px;
      height: 70px;
      font-size: 12px;
    }

  }
  @media (max-width: 991px) {
    .prod-cycle .dropdown-toggle::after {
      content:"";
      display:none

    }
  }
